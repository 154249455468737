import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../styles/recruit.module.scss"

const Recruit = () => {
  return (
    <Layout>
      <Seo
        title="Recruit | 株式会社アローズ"
        description="進化する、持続可能なビジネスへの挑戦。"
      />
      <div id="recruit">
        <div className={style.mv}>
          <div className={style.inner}>
            <h1>RECRUIT</h1>
            <h2 className={style.catch}>採用について</h2>
          </div>
        </div>
      </div>
      <div className={style.contentsWrap}>
        <div className={style.message}>
          <p>MESSAGE</p>
          <h2>
            進化する、持続可能な
            <br className={style.hiddenPC} />
            ビジネスへの挑戦
          </h2>
        </div>
        <section className={style.recruit}>
          <p>
            「市場ニーズ」を的確にとらえ、「ヒット商品」を開発・販売するスキルは、時代、業界を問わず求められています。
            <br />
            大企業では１０年～２０年かけて携わるその全てのプロセスを、わずか２～３年で経験することで、
            <br />
            ビジネスにおいて普遍的に求められるスキルを短期間で自分のモノにする事ができます。
          </p>
          <div className={style.entryBox}>
            <h3>
              アローズの募集要項・
              <br className={style.hiddenPC} />
              エントリーはこちら
            </h3>
            <div className={style.entryBoxInner}>
              <div className={style.rikunavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../images/recruit-bnr01.png"
                    alt="リクナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={199}
                    height={44}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  リクナビからエントリー
                </a>
              </div>
              <div className={style.mynavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../images/recruit-bnr02.png"
                    alt="マイナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={172}
                    height={41}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  マイナビからエントリー
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Recruit
